import axios from 'axios';
import canUseDOM from '@lib/canUseDOM';

export const windowHost = () => (canUseDOM ? window.location.hostname : '');
export const isProd = () => !local && !preprod;
export const local = windowHost().indexOf('localhost') !== -1;
export const preprod = windowHost().indexOf('preprod') !== -1;

export const referralApiUrl = () => {
  if (canUseDOM && (local || preprod)) return 'https://bp-preprod.askzeta.com/graphql';
  return 'https://card-pt.askzeta.com/graphql';
};

export const referralSignupUrl = () => {
  if (canUseDOM && (local || preprod)) return 'https://card-preprod.askzeta.com/';
  return 'https://card.askzeta.com/';
};

const headers = {
  'Content-Type': 'application/json',
  Authorization: null,
};

export const storeReferralToken = token => {
  const expirationDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  document.cookie = `ZJCReferrerToken=${token}; domain=.askzeta.com; SameSite=Lax; path=/; expires=${expirationDate.toUTCString()}; Secure`;
};

export const trackReferral = async ({ slug, offerCode }) => {
  const result = await axios.post(
    referralApiUrl(),
    {
      query: `mutation trackReferral($input: TrackReferralInput!) {
    trackReferral(input: $input) {
      referrerName
      referralToken
      referredAmountInCents
      referrerType
      errors {
        message
        path
      }
    }
  }`,
      variables: {
        input: {
          slug,
          offerCode,
        },
      },
    },
    {
      withCredentials: true,
      headers: headers,
    },
  );
  return result.data.data.trackReferral;
};
